import './Landing.css';
import React, {ReactElement} from 'react';
import {Link} from "react-router-dom";

function Landing(): ReactElement {
    return (
        <div className="flex flex-column justify-content-center text-right landing-content">
            <Link to="/stage" className="no-underline cursor-pointer">
                <div className="flex landing-link-content">
                    <div>
                        <div className="text-white landing-title">ANABEL OLGUIN</div>
                        <div className="text-white landing-subtitle">COSTUME DESIGN</div>
                    </div>
                    <div className="text-pink-200 text-lg align-content-center ml-3">
                        &rarr;
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Landing;
