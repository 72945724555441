import {Card} from "./Card.model";

export const StageContent: Card[] = [
    {
        title: 'Hedda Gabler',
        text: [
            'Written by Henrik Ibsen',
            'University of California San Diego 2024',
            'Directed by Lisa Portes'
        ],
        image: '/resource/hedda-gabler/image_00001.jpg',
        imageAttribution: 'Tzu Yu Su',
        path: 'stage/hedda-gabler',
        resource: 'hedda-gabler'
    },
    {
        title: 'Dead Girls Quinceañera',
        text: [
            'Written by Phanesia Pharel',
            'Wagner New Play Festival 2024',
            'Directed by Lamar Perry'
        ],
        image: '/resource/dgq/image_00006.png',
        imageAttribution: '',
        path: 'stage/dgq',
        resource: 'dgq'
    },
    {
        title: 'WinterWorks 24',
        text: [
            '"Open Your Eyes"',
            'WinterWorks 2024',
            'Choreography by Kara Mack'
        ],
        image: '/resource/winter-works/image_00005.jpeg',
        imageAttribution: 'Doug McMinimy',
        path: 'stage/winter-works',
        resource: 'winter-works'
    },
    {
        title: 'The Promise',
        text: [
            'Written by Jose Rivera',
            'University of California San Diego 2023',
            'Directed by Ludmila de Brito'
        ],
        image: '/resource/the-promise/image_00001.jpeg',
        imageAttribution: 'Rich Soublet',
        path: 'stage/the-promise',
        resource: 'the-promise'
    },
    {
        title: 'नेहा & Neel',
        text: [
            'Written by Ankita Raturi',
            'Wagner New Play Festival 2023',
            'Directed by Rosie Glen-Lambert'
        ],
        image: '/resource/neha-and-neel/image_00009.jpg',
        imageAttribution: 'Rich Soublet',
        path: 'stage/neha-and-neel',
        resource: 'neha-and-neel'
    }
];
